import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { RouteSaturated } from 'src/typings/job';
import { map, filter, first, switchMap } from 'rxjs/operators';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { MainNavStateService } from 'src/app/components/main-nav/state.service';

@Component({
    selector: 'app-truck-info',
    templateUrl: './truck-info.component.html',
    styleUrls: ['./truck-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TruckInfoComponent {
    private _route$ = new BehaviorSubject<RouteSaturated>(null);
    private togglingLock$ = new BehaviorSubject<boolean>(false);
    private route$ = this._route$.pipe(filter(route => !!route));
    @Input() set route(route: RouteSaturated) {
        this._route$.next(route);
    }
    @Input() withLock = true;
    @Input() badge = null;
    @Input() solidStyle = false;
    @HostBinding('class.solid') get solid() {
        return this.solidStyle;
    }
    @Input() scale = 1;
    @Input() featureFlags: any;

    constructor(
        private utilities:UtilitiesService,
        private mainNav: MainNavStateService,
    ) { }

    public routeColor$ = (
        this.route$.pipe(
            map(route => {
                return route.color;
            })
        )
    );

    public lockColor$ = (
        this.route$.pipe(
            map(route => route.locked ? '#222222' : route.color)
        )
    );

    public lockIcon$ = (
        this.route$.pipe(
            map(route => route.locked ? 'lock' : 'lock-open')
        )
    );

    public async toggleLock() {
        if (this.togglingLock$.value){
            return;
        }
        const route =  await firstValueFrom(this.route$);
        const targetLockState = !(route.locked);
        try{
            this.togglingLock$.next(true);
            this.mainNav.setUpdating(true, `${targetLockState ? 'Locking' : 'Unlocking'} route.`);
            let result = await route.setRouteLock(targetLockState);
            if (result.errors) {
                this.utilities.notifyError(`Error changing route lock. ${result.errors}`);
                return;
            }
            this.utilities.notifySuccess(`Route ${targetLockState ? 'locked' : 'unlocked'} successfully.`);
        }
        catch(err){
            if(err.details){
                this.utilities.notifyError(err.details);
                return;
            }
            this.utilities.notifyError(`Unexpected error ${targetLockState ? 'Locking' : 'Unlocking'} a route. Please contact system admin`);
        }
        finally{
            this.togglingLock$.next(false);
            this.mainNav.setUpdating(false);
        }
    }

    public routeIsLocked$ = (
        this.route$.pipe(
            map(route => route.locked)
        )
    );

    public driverName$ = (
        this.route$.pipe(
            map(route => {
                if(this.featureFlags?.virtualCarrier){
                    return `${route.lastVirtualCarrierName || 'No route'}: ${(!route.virtualCarrier) && route.carrierName ? route.carrierName : 'No Carrier Assigned'}`
                }
                return route.carrierName;
            })
        )
    );

    public carrierName$ = this.driverName$;

    public carrierInitial$ = (
        this.carrierName$.pipe(
            map(name => name[0].toUpperCase())
        )
    );

}
