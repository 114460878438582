<form [formGroup]="editForm" (ngSubmit)="onEditFormSubmit(editForm.value)" *ngIf="(columnSetupReady$ | async)">
    <h2 mat-dialog-title>{{data.name}}</h2>
    <div class="create-update-user" *ngIf="!data.virtual" (click)="createOrUpdateUserDialog()">
        <app-icon name="person"></app-icon>
        <span *ngIf="!data.userId">Create User</span>
        <span *ngIf="data.userId">Update User</span>
    </div> 
    <mat-dialog-content>   
            <div class="row">
                <div class="col-lg-3">
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" type="text" />
                    </mat-form-field>
                </div>        
                <div class="col-lg-3">
                    <mat-form-field>
                        <mat-label>Staff</mat-label>
                        <input matInput formControlName="number_of_staff" type="number" />
                    </mat-form-field>
                </div>        
                <div class="col-lg-1">
                    <mat-label>Color</mat-label>
                    <input matInput formControlName="color" type="color" />
                </div>
                <div class="col-lg-1">
                    <mat-checkbox  formControlName="installed_on_truck">Installer</mat-checkbox>
                </div>
                <div class="col-lg-1" *ngIf="(featureFlags$ | async)?.virtualCarrier">
                    <mat-checkbox  formControlName="virtual">Virtual</mat-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <mat-form-field>
                        <mat-label>Max Weight</mat-label>
                        <input matInput formControlName="max_weight" type="number" />
                    </mat-form-field>
                </div>
                <div class="col-lg-3">
                    <mat-form-field>
                        <mat-label>Capacity (cuft)</mat-label>
                        <input matInput formControlName="cubic_feet" type="number" />
                    </mat-form-field>
                </div>
                <div class="col-lg-3">
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" type="text" [readonly]="!!data.userId" />
                    </mat-form-field>
                </div>
                <div class="col-lg-3">
                    <mat-form-field>
                        <mat-label>Priority</mat-label>
                        <input matInput formControlName="priority" type="number" />
                    </mat-form-field>
                </div>
            </div>
            <div formGroupName="defaults">
                <h5>Time</h5>
                <div class="row" >
                    <div class="col-lg-4">
                        <mat-form-field>
                            <mat-label>Start</mat-label>
                            <input matInput [owlDateTime]="startTime" [owlDateTimeTrigger]="startTime" formControlName="startTime" />
                            <owl-date-time #startTime [pickerType]="'timer'" [hour12Timer]="'true'"></owl-date-time>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field>
                            <mat-label>End</mat-label>
                            <!-- <input matInput formControlName="endTime" type="text" /> -->
                            <input matInput [owlDateTime]="endTime" [owlDateTimeTrigger]="endTime" formControlName="endTime" />
                            <owl-date-time #endTime [pickerType]="'timer'" [hour12Timer]="'true'"></owl-date-time>
                        </mat-form-field>
                    </div>
                </div>    
                <h5>Start Location</h5>
                <div class="row" formGroupName="startLocation">
                    <div class="col-lg-4">           
                        <mat-form-field>
                            <mat-label>Street Name</mat-label>
                            <input matInput formControlName="streetName" type="text" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">
                        <mat-form-field>
                            <mat-label>Street Number</mat-label>
                            <input matInput formControlName="streetNumber" type="text" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">    
                        <mat-form-field>
                            <mat-label>City</mat-label>
                            <input matInput formControlName="city" type="text" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">
                        <mat-form-field>
                            <mat-label>State</mat-label>
                            <input matInput formControlName="state" type="text" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">
                        <mat-form-field>
                            <mat-label>Postal Code</mat-label>
                            <input matInput formControlName="postalCode" type="text" />
                        </mat-form-field>
                    </div>
                </div>
                <h5>End Location</h5>
                <div class="row" formGroupName="endLocation">
                    <div class="col-lg-4">           
                        <mat-form-field>
                            <mat-label>Street Name</mat-label>
                            <input matInput formControlName="streetName" type="text" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">
                        <mat-form-field>
                            <mat-label>Street Number</mat-label>
                            <input matInput formControlName="streetNumber" type="text" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">    
                        <mat-form-field>
                            <mat-label>City</mat-label>
                            <input matInput formControlName="city" type="text" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">
                        <mat-form-field>
                            <mat-label>State</mat-label>
                            <input matInput formControlName="state" type="text" />
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">
                        <mat-form-field>
                            <mat-label>Postal Code</mat-label>
                            <input matInput formControlName="postalCode" type="text" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
    
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="row w-100"> 
            <div class="col-lg-6" style="margin-left: -15px;padding-top: 4px;padding-left: 0;" align="start">
                <mat-checkbox id="isUpdateRoute">Update route (Only today)</mat-checkbox>
            </div>
            <div class="col-lg-6 text-right" align="end">
                <button mat-raised-button type="button" (click)="close()">Cancel</button>
                <button mat-raised-button type="submit" color="primary">Save</button>
            </div>
        </div>      
    </mat-dialog-actions>
</form>