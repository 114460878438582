<div class="header">
    <div class="route-info">
        <app-truck-info
            [route]="(route$ | async)"
            [withLock]="false"
            [solidStyle]="true"
            [featureFlags]="featureFlags$ | async"
            ></app-truck-info>
    </div>
    <div class="tabs">
        <div class="tab"
            *ngFor="let tab of tabs$ | async; trackBy: trackByFn"
            [style.background-color]="tab.bgColor"
            [ngClass]="tab.cssClass" [innerText]="tab.name | uppercase" (click)="tab.clickHandler()"></div>
    </div>
</div>
<app-chat-box *ngIf="(selectedTab$ | async) === 'messages'"
    [messages]="(messages$ | async)" otherRecipient="" [userName]="formatDriverName(route$ | async)" [addMessageCallback]="addMessageCallback" [viewMoreMessagesCallback]="viewMoreMessagesCallback"></app-chat-box>
