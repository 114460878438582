import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditServiceTimeComponent } from './edit-service-time/edit-service-time.component';
import { EditStatusComponent } from './edit-status/edit-status.component';
import { EditItemStatusComponent } from './edit-item-status/edit-item-status.component';
import { EditTimeWindowComponent } from './edit-time-window/edit-time-window.component';
import { EditOrderComponent } from './edit-order/edit-order.component';
import { SelectCarrierComponent } from './select-carrier/select-carrier.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TruckInfoComponent } from './truck-info/truck-info.component';
import { IconsComponent } from './icons/icons.component';
import { ImageGalleryComponent } from './img-gallery/img-gallery.component';
import { JobQuickViewComponent } from './job-quick-view/jobQuickView.component';
import { DetailsComponent, DetailsModalService } from './details-view/details-view.component';
import { ChatBoxComponent } from './details-view/chat-box/chat-box.component';
import { RouteViewComponent } from './details-view/route-view/route-view.component';
import { StopViewComponent } from './details-view/stop-view/stop-view.component';
import { NotesComponent } from './details-view/stop-view/notes/notes.component';
import { ModalModule } from '../modal/modal.module';
import { DatePipe } from './date.pipe';
import { DatePickerComponent, DatePickerModal } from './date-picker/date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { AppTooltipComponent } from './tooltip/tooltip.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { RouteCardComponentN } from './route-card/route-card.component';
import { ConfirmationsIconComponent } from './route-card/confirmations-icon/confirmations-icon.component';
import { DonutChartComponent } from './route-card/donut-chart/donut-chart.component';
import { TimeConfirmationRequestComponent } from './time-confirmation-request/time-confirmation-request.component';
import { TimeConfirmationRequestModal } from './time-confirmation-request/time-confirmation-request-modal.service';
import { CardHeaderComponent } from './card-header/card-header.component';
import { CardButtonComponent } from './card-button/card-button.component';
import { CardComponent } from './card/card.component';
import { RouteFooterComponent } from './route-footer/route-footer.component';
import { TimeConfirmationResultsComponent } from './time-confirmation-results/time-confirmation-results.component';
import { TimeConfirmationResultsModal } from './time-confirmation-results/time-confirmation-results-modal.service';
import { SlCustomerConfirmationModule } from '../sl-customer-confirmation/sl-customer-confirmation.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { DialogComponent, DialogLauncher } from './dialog/dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StopCardComponent } from './stop-card/stop-card.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { EtaClockComponent } from './stop-card/eta-clock/eta-clock.component';
import { StopPinComponent } from './pins/stop-pin/stop-pin.component';
import { MapPinComponent } from './pins/map-pin/map-pin.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatBadgeModule } from '@angular/material/badge';
import { EditCustomerPhoneComponent } from './edit-customer-phone/edit-customer-phone.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { SendSummaryReportComponent } from './send-summary-report/send-summary-report.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { ShowSyncingErrorsComponent } from './show-syncing-errors/show-syncing-errors.component';

@NgModule({
    imports: [
        CommonModule,
        ScrollingModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatSelectModule,
        SlCustomerConfirmationModule,
        NgbModule,
        ModalModule,
        MatBadgeModule,
        MatCheckboxModule,
        MatListModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatInputModule
    ],
    declarations: [
        AppTooltipComponent,
        ConfirmationsIconComponent,
        DonutChartComponent,
        DatePipe,
        DetailsComponent,
        EtaClockComponent,
        EditServiceTimeComponent,
        EditStatusComponent,
        EditItemStatusComponent,
        EditTimeWindowComponent,
        EditOrderComponent,
        SelectCarrierComponent,
        EditCustomerPhoneComponent,
        TruckInfoComponent,
        IconsComponent,
        ImageGalleryComponent,
        JobQuickViewComponent,
        DetailsComponent,
        ChatBoxComponent,
        RouteViewComponent,
        StopViewComponent,
        StopCardComponent,
        CopyToClipboardComponent,
        NotesComponent,
        DatePickerComponent,
        TooltipDirective,
        RouteCardComponentN,
        TimeConfirmationRequestComponent,
        CardHeaderComponent,
        CardButtonComponent,
        CardComponent,
        RouteFooterComponent,
        TimeConfirmationResultsComponent,
        SpinnerComponent,
        DialogComponent,
        StopCardComponent,
        StopPinComponent,
        MapPinComponent,
        ResetPasswordComponent,
        SendSummaryReportComponent,
        ShowSyncingErrorsComponent,
    ],
    entryComponents: [ResetPasswordComponent],
    exports: [
        AppTooltipComponent,
        TooltipDirective,
        DetailsComponent,
        EditServiceTimeComponent,
        EditStatusComponent,
        EditItemStatusComponent,
        EditTimeWindowComponent,
        EditOrderComponent,
        SelectCarrierComponent,
        EditCustomerPhoneComponent,
        TruckInfoComponent,
        IconsComponent,
        ImageGalleryComponent,
        JobQuickViewComponent,
        DetailsComponent,
        ChatBoxComponent,
        RouteViewComponent,
        RouteCardComponentN,
        StopViewComponent,
        StopPinComponent,
        StopCardComponent,
        NotesComponent,
        MapPinComponent,
        EtaClockComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        DetailsModalService,
        ResetPasswordComponent,
        DatePickerModal,
        DialogLauncher,
        TimeConfirmationRequestModal,
        TimeConfirmationResultsModal,
    ]
})
export class SlComponentsModule { }
