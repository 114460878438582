<div class="container">
  <ng-container>
    <!-- table  -->
    <table mat-table [dataSource]="dataSource" *ngIf="(columnSetupReady$ | async)" matSort class="mat-elevation-z8" multiTemplateDataRows>
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element" class="color-icon">
          <app-icon name="circle" [ngStyle]="{color: element.color}"></app-icon></td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element">
          {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Time </th>
        <td mat-cell *matCellDef="let element">{{element.defaults.startTime | date:'h:mm a'}}</td>
      </ng-container>

      <ng-container matColumnDef="endTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> End Time </th>
        <td mat-cell *matCellDef="let element">
          {{element.defaults.endTime | date:'h:mm a'}} </td>
      </ng-container>

      <ng-container matColumnDef="number_of_staff">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Staff </th>
        <td mat-cell *matCellDef="let element">
          {{element.number_of_staff}} </td>
        </ng-container>
  
      <ng-container matColumnDef="installed_on_truck">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Installer </th>
        <td mat-cell *matCellDef="let element">
          {{element.installed_on_truck}} </td>
      </ng-container>

      <ng-container *ngIf="(featureFlags$ | async).virtualCarrier" matColumnDef="virtual">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Virtual </th>
        <td mat-cell *matCellDef="let element">
          {{element.virtual}} </td>
      </ng-container>

      <ng-container matColumnDef="max_weight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Max Weight (lbs) </th>
        <td mat-cell *matCellDef="let element">
          {{element.max_weight}} </td>
      </ng-container>   

      <ng-container matColumnDef="cubic_feet">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Capacity (cuft) </th>
        <td mat-cell *matCellDef="let element">
          {{element.cubic_feet}} </td>
      </ng-container>

      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Priority </th>
        <td mat-cell *matCellDef="let element">
          {{element.priority}} </td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
        <td mat-cell *matCellDef="let element">
          <app-icon *ngIf="element.userId" name="person"></app-icon> </td>
      </ng-container>
      
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <div class="actions" *ngIf="!element.locked">
            <app-icon title="edit" class="pencil-icon" size="15" name="pencil" (click)="edit(element)"></app-icon>
            <app-icon title="clone" size="15" name="clone" (click)="clone(element)"></app-icon>
            <app-icon title="delete" size="15" name="close-window-cross" *ngIf="!element.userId" (click)="delete(element)"></app-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="row w-100">
                <div class="col-lg-4">
                    <h5>Start Location</h5><br />
                    <p>
                      {{element.defaults.startLocation.streetNumber}} {{element.defaults.startLocation.streetName}} {{element.defaults.startLocation.city}} {{element.defaults.startLocation.state}} {{element.defaults.startLocation.postalCode}}
                    </p>
                   
                </div>
                <div class="col-lg-4">
                  <h5>End Location</h5><br />
                  <p>
                    {{element.defaults.endLocation.streetNumber}} {{element.defaults.endLocation.streetName}} {{element.defaults.endLocation.city}} {{element.defaults.endLocation.state}} {{element.defaults.endLocation.postalCode}}
                  </p>
                </div>
                <div class="col-lg-4">
                  <h5>E-Mail</h5><br />
                  <p>
                    {{element.email}}
                  </p>
                </div>
              </div>
          </div>
        </td>
      </ng-container>
  
        <!-- binding table header -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <!-- binding table row -->
      <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
      (click)="expandedElement = element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
  </ng-container>
  
</div>
