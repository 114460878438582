import { Component, OnInit, ChangeDetectionStrategy, Inject, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/shared/sl-data/data.service';
import { DataPathsService } from 'src/app/shared/sl-data/data-paths.service';
import { toPromise } from 'src/app/services/utils';
import * as moment from 'moment';
import { SlPlanningService } from 'src/app/shared/sl-planning/sl-planning.service';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CreateUserModalComponent } from '../create-user-modal/create-user-modal.component';
import { DataEnvService } from 'src/app/shared/sl-data/data-env.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';


@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditModalComponent implements OnInit {
  public editForm: FormGroup;
  public carriersPath: string = '';
  public routePath: string = '';
  @ViewChild('isUpdateRoute', { read: ElementRef }) isUpdateRoute: ElementRef;
  constructor(public dialogRef: MatDialogRef<EditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder, private dataCtrl: DataService, private paths: DataPathsService, private planning: SlPlanningService, 
    private dialog: MatDialog, public dataEnv: DataEnvService) {
  }

  public featureFlags$ = this.dataEnv.featureFlags$;
  public columnSetupReady$ = new BehaviorSubject(false);
  async ngOnInit() {
    const fflags: any = await firstValueFrom(this.featureFlags$);
    const formGroup = {
      'name': ['', Validators.compose([Validators.required])],
      'defaults': this.fb.group({
        "startTime": [''], endTime: [''],
        'startLocation': this.fb.group({ 'streetName': [''], 'streetNumber': ['125'], 'city': [''], 'state': [''], 'postalCode': ['19019'] }), 'endLocation': this.fb.group({ 'streetName': [''], 'streetNumber': ['125'], 'city': [''], 'state': [''], 'postalCode': ['10001', Validators.compose([Validators.required, Validators.minLength(6)])] })
      }),
      'installed_on_truck': ['', Validators.compose([Validators.required])],
      'virtual': [false, Validators.compose([Validators.required])],
      'cubic_feet': ['', Validators.compose([Validators.required])],
      'max_weight': ['', Validators.compose([Validators.required])],
      'number_of_staff': ['', Validators.compose([Validators.required])],
      'email': ['', Validators.compose([Validators.email])],
      'priority': ['', Validators.compose([Validators.required])],
      'color': ['', Validators.compose([Validators.required])]
    };
    if(!fflags?.virtualCarrier){
      delete formGroup.virtual;
    }
    this.columnSetupReady$.next(true);
    this.editForm = this.fb.group(formGroup);
    setTimeout(() => {
      let cuurentDate = moment().format('YYYY-MM-DD');
      this.data.defaults.startTime = new Date(this.data.defaults.startTime);
      this.data.defaults.endTime = new Date(this.data.defaults.endTime);
      this.editForm.patchValue(this.data);
    })
    this.carriersPath = await toPromise(this.paths.carriers$);
    this.routePath = await toPromise(this.paths.routes$);
  }

  onEditFormSubmit(value) {
    value.defaults.startTime = moment(value.defaults.startTime).format('HH:mm:ss');
    const isUpdateRoute: any = document.querySelector('#isUpdateRoute-input');
    if (isUpdateRoute.checked) {
      const date = moment().format('YYYY-MM-DD')
      this.dataCtrl.getCollection({ path: this.routePath, queries: [['date', '==', date], ['carrierId', '==', this.data.docId]] }).subscribe((res) => {
        if (res.docs.length != 0) {
          this.planning.optimizeRoute(res.docs[0].id);
        }
      })
    }

    value.defaults.endTime = moment(value.defaults.endTime).format('HH:mm:ss');
    this.dataCtrl.updateDoc(this.carriersPath + '/' + this.data.docId, value).then((res) => {
      console.log("value", res);
    });
    this.dialogRef.close();
  }

  changeStartTime(startTime) {
    console.log(startTime)
  }

  close() {
    this.dialogRef.close();
  }

  createOrUpdateUserDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.maxWidth = '50%';
    dialogConfig.data = {email:this.data.email, password:"", carrierId: this.data.docId, parent: this.dialogRef, userId: this.data.userId};
    const dialogRef = this.dialog.open(CreateUserModalComponent, dialogConfig);
  }

}
