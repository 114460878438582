import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouteSaturated } from 'src/typings/job';
import { BehaviorSubject, combineLatest, ReplaySubject, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { CommunicationsData } from 'src/app/shared/sl-data/communications';
import { MessageDoc } from 'src/app/shared/sl-data/messages/messages-types';
import { MessagesData } from 'src/app/shared/sl-data/messages';
import { DataEnvService } from 'src/app/shared/sl-data/data-env.service';

export type RouteDetailsTab = 'messages';

@Component({
    selector: 'app-route-view',
    templateUrl: './route-view.component.html',
    styleUrls: ['./route-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteViewComponent {
    public route$ = new BehaviorSubject<RouteSaturated>(null);
    public selectedTab$ = new BehaviorSubject<RouteDetailsTab>('messages');
    public messages$ = new BehaviorSubject<MessageDoc[]>(null);
    private messagesDatesLimit$ = new BehaviorSubject<number>(5);

    @Input() set route(route: RouteSaturated) {
        this.route$.next(route);
    }
    @Input() set tab(tab: RouteDetailsTab) {
        this.selectedTab$.next(tab);
    }

    constructor(
        private communications: CommunicationsData,
        private messagesService: MessagesData,
        public dataEnv: DataEnvService
    ) { 
    }
    public featureFlags$ = this.dataEnv.featureFlags$;
    private subscriptions: Subscription[] = [];
    ngOnInit(): void {
        this.subscriptions.push(combineLatest([this.selectedTab$,this.messages$]).subscribe(([selectedTab, messages]) => {
            if(selectedTab === 'messages'){
                //async call, we want to display messages quickly
                this.markMessagesAsRead(messages).catch(err=> {console.log(err)});
            }
        }));
        this.subscriptions.push(combineLatest([this.route$.pipe(filter(route => !!route)),this.messagesDatesLimit$]).subscribe(([route, messagesDatesLimit]) => {
            this.messagesService.latestChatMessages$(route.carrierId, messagesDatesLimit).subscribe(this.messages$);
        }));

    }

    ngOnDestroy(): void {
        for(let subscription of this.subscriptions){
            subscription.unsubscribe();
        }
    }

    public tabs$ = combineLatest(
        this.selectedTab$,
        this.route$.pipe(filter(route => !!route))
    ).pipe(
        map(([selected, route]) => {
            return [
                'messages'
            ].map((tab: RouteDetailsTab) => ({
                name: tab,
                cssClass: selected === tab ? 'selected' : '',
                bgColor: selected === tab ? route.color : '#FFFFFF',
                clickHandler: () => this.selectedTab$.next(tab)
            }));
        })
    );

    public trackByFn(i, t) {
        return i;
    }

    private tempDatesCache : String[] = [];
    private markMessagesAsRead = async (messages: MessageDoc[] = []) => {
        const unreadMessages = messages ? messages.filter(m => m.from === 2 && m.isUnread) : [];
        //If all messages are read, they can be shown as is.
        if(unreadMessages.length === 0){
            return;
        }

        const datesToUpdate = unreadMessages.reduce((appDates, currentValue) => {
            const currentDate = currentValue.dateUtc.toDate().toISOString().split('T')[0].toString();    
            !appDates.includes(currentDate) && appDates.push(currentDate);
            return appDates;
        }
        ,[]);
        if(this.tempDatesCache.length > 0){
            return;
        }
        this.tempDatesCache = datesToUpdate;
        //Mark all customer messages as read for this job
        for (let i = 0; i < datesToUpdate.length; i++) {
            const appDate = datesToUpdate[i];
            await this.communications.dispatcherSetChatMessagesAsRead(this.route$.value.carrierId, appDate);    
        }
        this.tempDatesCache = [];
    }

    public formatDriverName(route){
        return `Driver: ${route?.carrierName}`;
    }

    public addMessageCallback = async (message : string) => {
        const appDate = (new Date()).toISOString().split('T')[0].toString();
        await this.communications.dispatcherAddChatMessage(message, appDate, this.route$.value.carrierId);        
    }

    public viewMoreMessagesCallback = (limit : number) => {
        this.messagesDatesLimit$.next(limit);
    }
    
};